import React, { useContext } from "react";
import { AuthContext } from "../Authentication";
import { useNavigate } from "react-router-dom";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import { Button, Grid, Paper, styled } from "@mui/material";
import AssistanceRequests from "../AssistanceRequest/AssistanceRequests";
import { RouteConstants } from "../../Constants/RouteConstants";
import UserProfile from "../UserProfile";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const MainGridItemWithPadding = styled(Grid)({
  padding: "2vh"
});

const ProfileTitleGridItem = styled(Grid)({
  paddingTop: "1em",
  fontWeight: "bold",
  fontSize: "1.75vh"
});

const RequestsTitleGridItem = styled(Grid)({
  paddingTop: "1em",
  fontWeight: "bold",
  fontSize: "1.75vh"
});

const EditIconStyled = styled(EditIcon)({
  fontSize: '1.5rem',
  verticalAlign: 'middle',
  marginLeft: '0.5rem'
});

const AddIconStyled = styled(AddIcon)({
  fontSize: '1.5rem',
  verticalAlign: 'middle',
  marginLeft: '0.5rem'
});

const Portal: React.FC = () => {
  const userFromContext = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Grid container>
      <MainGridItemWithPadding item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <>
              {userFromContext && userFromContext.userProfile && userFromContext.userProfile.verificationStatus === VerificationStatus.Approved && (
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <Paper>
                      <ProfileTitleGridItem item xs={12}>Profile</ProfileTitleGridItem>
                      <Grid item xs={12}>
                        <Button onClick={() => navigate(RouteConstants.Profile)}>
                          Edit Profile 
                          <EditIconStyled />
                        </Button>
                      </Grid>
                      <UserProfile />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Paper>
                      <RequestsTitleGridItem item xs={12}>Assistance Requests</RequestsTitleGridItem>
                      <Grid item xs={12}>
                        <Button onClick={() => navigate(RouteConstants.Apply)}>
                          New Assistance Request 
                          <AddIconStyled />
                        </Button>
                      </Grid>
                      <AssistanceRequests />
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </>
          </Grid>
        </Grid>
      </MainGridItemWithPadding>
    </Grid>
  );
};

export default Portal;