import React from "react";
import { ButterCampaign, ButterPlan } from "../../Domain/ButterInfo";
import { Grid, Paper, Table, TableBody, TableRow, TableCell, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getNumericAsCurrencyString } from "../../Helpers/StringHelpers";
import CustomCircularProgress from "../shared/CustomCircularProgress";

const ButterHeaderText = styled(Grid)(() => ({
  fontWeight: "bold",
  padding: "1em",
}));

const StyledPaper = styled(Paper)(() => ({
  padding: "1em",
}));

interface IDonationSummary {
  butterPlans: ButterPlan[];
  campaign: ButterCampaign;
  isLoading: boolean;
}

const DonationSummary: React.FC<IDonationSummary> = ({ butterPlans, campaign, isLoading }) => {
  const totalMonthlyDonors = butterPlans?.filter(p => p.status === 'active').length;

  const totalMonthlyDonorAmounts = butterPlans
    ?.filter(p => p.status === 'active')
    .reduce((sum, p) => sum + p.amount, 0) || 0;

  return (
    <StyledPaper>
      {isLoading && <CustomCircularProgress message="Loading donation totals" />}
      {!isLoading && (
        <Grid item>
          <Box>
            <Grid container style={{ padding: "1em" }} justifyContent="center">
              <ButterHeaderText item xs={12}>
                Donation Summary
              </ButterHeaderText>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Raised by GiveButter (all time)</TableCell>
                    <TableCell>{getNumericAsCurrencyString(campaign?.raised ?? 0)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Give Butter Donors (single donation & reoccurring)</TableCell>
                    <TableCell>{campaign?.donors}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Monthly Donations</TableCell>
                    <TableCell>{getNumericAsCurrencyString(totalMonthlyDonorAmounts)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Monthly Donors</TableCell>
                    <TableCell>{totalMonthlyDonors}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>                                
              <Grid item><a href="https://dashboard.givebutter.com/">Give Butter Link</a></Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </StyledPaper>
  );
};

export default DonationSummary;
